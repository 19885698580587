import React from 'react';
import {FooterLinksContainer, FooterLink, FooterLinkItems, FooterLinksWrapper, FooterLinkTitle, FooterWrap, FooterContainer,
  SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, SocialIconLink, WebsiteRights} from './FooterElements'
import {FaFacebook, FaInstagram, FaYoutube} from "react-icons/all";

const Footer = () => {
  return (
    <>
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Посилання</FooterLinkTitle>
                <FooterLink to='/message'>Написати</FooterLink>
                <FooterLink to='/'>Документація</FooterLink>
                <FooterLink to='/'>Законодавство</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Соціальні мережі</FooterLinkTitle>
              <FooterLink to='https://facebook.com'>Facebook</FooterLink>
              <FooterLink to='https://instagram.com'>Instagram</FooterLink>
              <FooterLink to='https://youtube.com'>Youtube</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>



    <SocialMedia>
      <SocialMediaWrap>
        <SocialLogo to='/'>CAHEKO</SocialLogo>
        <WebsiteRights>CAHEKO © {new Date().getFullYear()}. Всі права захищено.</WebsiteRights>
        <SocialIcons>
          <SocialIconLink href='https://facebook.com' target='_blank' aria-label='Facebook'><FaFacebook/></SocialIconLink>
          <SocialIconLink href='https://instagram.com' target='_blank' aria-label='Instagram'><FaInstagram/></SocialIconLink>
          <SocialIconLink href='https://youtube.com' target='_blank' aria-label='Youtube'><FaYoutube/></SocialIconLink>
        </SocialIcons>
      </SocialMediaWrap>
    </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  </>
  );
};

export default Footer;