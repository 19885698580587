import React, {useState} from 'react';
import Video from '../../videos/video4.mp4';
import {HeroContainer, VideoBg, HeroBg, HeroH1, HeroP, HeroContent, HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroElements';
import {Button} from '../ButtonElements';


const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type={'video/mp4'}/>
      </HeroBg>
      <HeroContent>
        <HeroH1>Будівництво починається з нас</HeroH1>
        <HeroP>До кінця 2021 року, всім новим клієнтам, повертаємо до 20% на кожне друге замовлення</HeroP>
        <HeroBtnWrapper>
          <Button
            to='/product'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary={true}
            smooth={true} duration={500} spy={true} exact='true' offset={-80}
          >
            Переглянути {hover ? <ArrowForward/> : <ArrowRight/>}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;