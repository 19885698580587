export const homeObjOne = {
  id: 'service',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Розроблення кар\'єрів',
  headLine: 'Послуги у сфері добування корисних копалин',
  description: 'Ми проводимо дослідження, розробляємо спеціальні технологічні карти ваших територій. Допомагаємо визначити види копалин, їх кількість та рентабельність видобування. ',
  buttonLabel: 'Подати заявку',
  imgStart: true,
  img: require('../../images/svg-14.svg').default,
  alt: 'Services',
  lnk: '/message',
  dark: true,
  primary: true,
  darkText: true
};

export const homeObjTwo = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Оптова торгівля',
  headLine: 'Добування декоративного та будівельного каменю',
  description: 'Ми проводимо добування декількох видів корисних копалин на своїх територіях та кар\'єрах. Це декоративний та будівельний камінь, а також вапняк, гіпс та крейда. На рівні з цим ми видобуваємо глинистий сланець, річковий пісок та щебень.',
  buttonLabel: 'Запитати',
  imgStart: false,
  img: require('../../images/svg-1.svg').default,
  alt: 'About',
  lnk: '/message',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTree = {
  id: 'contact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Наша адреса',
  headLine: 'м. Стрий',
  description: '+38(067) 370 7603, вул. Б. Лепкого 1/1, Львівська область, м. Стрий, Україна, 82400',
  buttonLabel: 'Написати',
  imgStart: true,
  img: require('../../images/svg-2.svg').default,
  alt: 'Contact',
  lnk: '/message',
  dark: true,
  primary: true,
  darkText: true
};

