import React from 'react';
import Message from "../components/Message";

const MessagePage = () => {
  return (
    <>
      <Message />
    </>
  );
};

export default MessagePage;