import React from 'react';
import {SidebarContainer, CloseIcon, Icon, SidebarMenu, SidebarWrapper, SidebarRoute, SidebarLink, SideBtnWrap} from "./SidebarElements";

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to={'product'} onClick={toggle}>Продукція</SidebarLink>
          <SidebarLink to={'service'} onClick={toggle}>Послуги</SidebarLink>
          <SidebarLink to={'about'} onClick={toggle}>Про нас</SidebarLink>
          <SidebarLink to={'contact'} onClick={toggle}>Контакти</SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute>
            Написати
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;