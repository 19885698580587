import React from 'react';
import {
  InfoContainer,
  Column1,
  BtnWrap,
  Img,
  ImgWrap,
  Column2,
  Heading,
  InfoRow,
  InfoWrapper,
  TextWrapper,
  Subtitle,
  TopLine,
  IFrame
} from "./InfoElements";
import { Button } from "../ButtonElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  darkText, 
  headLine,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  lnk
}) => {

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  <Button
                    to={lnk}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact={"true"}
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                    // primary={primary}
                  >{buttonLabel}</Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                {
                  id === 'contact'
                    ? <IFrame
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.769050917616!2d23.846007652179075!3d49.26182317922756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473a69f9d5551bb5%3A0xb347f68f91fa078c!2z0KHQkC3QndCV0JrQng!5e0!3m2!1sru!2sua!4v1634822481735!5m2!1sru!2sua"
                       allowFullScreen="" loading="lazy">
                    </IFrame>
                    : <Img src={img} alt={alt}/>
                }
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;