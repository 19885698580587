//yarn add --save styled-components
//yarn add react-router-dom
//yarn add react-icons --save
//yarn add react-scroll


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
