import React from 'react';

import {Container, FormContainer, FormH1, FormInput, FormLabel, FormWrap, Icon, Text, Form, FormButton, FormInputTA} from "./MessageElements";

const Message = () => {
  return (
    <>
    <Container>
      <FormWrap>
        <Icon to='/'>CAHEKO</Icon>
        <FormContainer>
          <Form action='https://formspree.io/f/xayazbav' method={"POST"}>
            <FormH1>Ваше повідомлення</FormH1>
            <FormLabel htmlFor={'for'}>Ваш телефон</FormLabel>
            <FormInput type={'email'}name={'_replyto'} required />
            <FormLabel htmlFor={'for'}>Повідомлення</FormLabel>
            <FormInputTA type={'text-area'} name={'message'} required />
            <FormButton type={'submit'}>Відправити</FormButton>
            <Text>Ми обов'язково вам перетелефонуємо стосовно вашого запиту, якщо вкажете свій номер телефону.</Text>
          </Form>
        </FormContainer>

      </FormWrap>
      
    </Container>
    </>
  );
};

export default Message;