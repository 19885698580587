import React from 'react';
import Icon1 from '../../images/01.jpg';
import Icon2 from '../../images/02.jpg';
import Icon3 from '../../images/03.jpg';
import {ServicesH1, ServicesIcon, ServicesWrapper, ServicesH2, ServicesP, ServicesContainer, ServicesCard, ServicesST1} from './ServeceElements'

const Services = () => {
  return (
    <ServicesContainer id='product'>
      <ServicesH1>Продукція CAHEKO</ServicesH1>
      <ServicesST1>
        На кар'єрі у Стрию, ми добуваємо річковий пісок для приготування бетонних або штукатурної сумішей, щебінь різних фракцій та будівельний камінь для ваших проектів.</ServicesST1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>Пісок річковий</ServicesH2>
          <ServicesP>Високий ступень очищення і відсутністю сторонніх включень, глинистих домішок і дрібних каменів</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon2}/>
          <ServicesH2>Щебінь</ServicesH2>
          <ServicesP>Неорганічний зернистий сипучий матеріал, який одержується шляхом дроблення гірських порід</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>Будівельний камінь</ServicesH2>
          <ServicesP>Використовується для виробництва щебеню, що застосовується як заповнювач бетонів</ServicesP>
        </ServicesCard>
      </ServicesWrapper>

    </ServicesContainer>
  );
};

export default Services;